import React, { useEffect } from 'react';

import { PrivacyPDFUrl } from '@constants/app';

export default () => {

  useEffect(() => {
    window.location.href = PrivacyPDFUrl;
  }, []);

  return <></>;
};
